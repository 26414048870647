<template>
    <v-container>
        <v-card class="ma-auto">
            <v-card-title>Chat</v-card-title>
            <v-card-text>
                <pre>{{teste}}</pre>
                <v-row dense>
                    <v-card 
                        elevation="1" 
                        outlined 
                        class="ma-2 pa-2 overflow-y-auto" 
                        width="100%"
                        max-height="300"
                        v-scroll.self="onScroll"
                    >
                        <div v-for="(m, i) in messages" :key="i">
                           <p :class="m.align">
                                
                               <v-chip :color="m.color" class="text-wrap py-2">
                                    <span class="grey--text">{{m.username}}</span>: {{m.message}}</v-chip>
                           </p>
                        </div>
                    </v-card>
                </v-row>
                <v-row dense>
                    <v-col>
                        <v-text-field
                            outlined
                            v-model="msg.username"
                            label="Usuário"
                        ></v-text-field>
                    </v-col>
                </v-row>
                <v-row dense>
                    <v-col cols="11">
                        <v-text-field
                            outlined
                            v-model="msg.message"
                            label="Mensagem"
                        ></v-text-field>
                    </v-col>
                    <v-col align="center">
                        <v-btn icon @click="send()"><v-icon size="45">mdi-send-circle-outline</v-icon></v-btn>
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>
    </v-container>
</template>
<script>
    let url = `ws://10.90.0.7:8000/ws/socket-server/`    
    const chatSocket = new WebSocket(url)
    
export default {
    name: 'Chat',
    data: () => ({
        scrollInvoked: 0,
        msg:{},
        msgs:[],
        teste: null
    }),
    methods:{
        send(){
            chatSocket.send(JSON.stringify(this.msg))
        },
        onScroll () {
            this.scrollInvoked++
        },
    },
    mounted(){
        let tmp = []
        chatSocket.onmessage = function(e){
            let data = JSON.parse(e.data);
            console.log(data)
            if(data.type === 'chat'){
                tmp.push(data)
            }
        }
        this.msgs = tmp
    },
    computed:{
        messages(){
            return this.msgs.map((e)=>{

                e.align = 'text-start'
                e.color = 'blue lighten-3' 
                if(e.username === this.msg.username){
                    e.align = 'text-end'
                    e.color = 'grey lighten-3'
                }
                return e
            })
        } 
    }
}
</script>